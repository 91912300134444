import React, { useState, useRef } from "react";
import AudioPlayer from "react-audio-player";
import { AiOutlineStop } from "react-icons/ai";

const AudioPlayerWithRepeat = ({ src, offAlarm }) => {
  const [play, setPlay] = useState(true);
  const audioRef = useRef(null);

  const handleEnded = () => {
    // If repeat is enabled, restart the audio playback
    if (play) {
      audioRef.current.audioEl.current.currentTime = 0;
      audioRef.current.audioEl.current.play();
    }
    setPlay(true);
  };
  
  return (
    <div>
      <AudioPlayer
        src={src}
        autoPlay
        controls
        ref={audioRef}
        onEnded={handleEnded}
        className="d-none"
      />

      <button
        type="button"
        className="btn btn-danger fs-5 fw-bold w-100"
        onClick={offAlarm}
      >
        <AiOutlineStop /> Stop Alarm
      </button>
    </div>
  );
};

export default AudioPlayerWithRepeat;
