import axios from "axios";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { AUTH_TOKEN, baseURl } from "../utils";

export const PatientInModel = ({
  show,
  patient,
  isLoading,
  setPrintModal,
  setPatientInModel,
  allPatient,
  shift_id,
}) => {
  const [formFields, setFormFields] = useState([{ title: "", value: "" }]);
  const [token_number, setTokenNumber] = useState(patient?.token_number);
  //patientIn
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseURl}/doctor/patient/in`,
        {
          token_id: patient?.id,
          details: formFields,
          token_number: token_number,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      setPatientInModel(false);
      allPatient(shift_id);
      //setPrintModal(true);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response.data.message,
      });
    }
  };

  const handleAddField = () => {
    setFormFields([...formFields, { title: "", value: "" }]);
  };

  const handleRemoveField = (index) => {
    const data = formFields.filter((_, i) => i !== index);
    setFormFields(data);
  };

  const handleSelectChange = (index, event) => {
    const newFields = [...formFields];
    newFields[index].title = event.target.value;
    setFormFields(newFields);
  };

  const handleInputChange = (event) => {
    //(index, event)
    //const newFields = [...formFields];
    //newFields[index].value = event.target.value;
    //setFormFields(newFields);
    const { name, value } = event.target;
    setTokenNumber(value);
  };

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title className="h6">
          Patient In {patient.patient && <>- {patient.patient.name}</>}
          {/* (T-{patient.token_number},{" "}
          {patient?.patient?.name || "No Name"}) */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit} className="py-3">
          {/* {formFields.map((field, index) => (
            <div className="row mb-3" key={index}>
              <div className="col-5">
                <label style={{fontSize:"14px"}}>Title</label>
                <Form.Control
                  type="text"
                  value={field.title}
                  onChange={(e) => handleSelectChange(index, e)}
                  size="sm"
                  name="title"
                  placeholder="Age"
                />
              </div>
              <div className="col-6">
              <label style={{fontSize:"14px"}}>Value</label>
                <Form.Control
                  type="text"
                  value={field.value}
                  onChange={(e) => handleInputChange(index, e)}
                  size="sm"
                  name="value"
                  placeholder="14 year"
                />
              </div>
              <div className="col-1">
              <label> </label>
                {index == 0 ? (
                  <Button variant="info" size="sm" onClick={handleAddField}>
                    +
                  </Button>
                ) : (
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleRemoveField(index)}
                  >
                    X
                  </Button>
                )}
              </div>
            </div>
          ))} */}

          <label style={{ fontSize: "14px" }}>Token Number</label>
          <Form.Control
            type="number"
            value={token_number}
            onChange={(e) => handleInputChange(e)}
            size="sm"
            name="token_number"
            placeholder=""
          />

          <div className="mt-3 text-end">
            <Button
              variant="secondary"
              onClick={() => setPatientInModel(false)}
            >
              Close
            </Button>
            <button
              disabled={isLoading}
              className="btn btn-primary ms-2"
              type="submit"
            >
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}{" "}
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
