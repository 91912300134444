import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../services/authService";
import { TOKEN_KEY, AUTH_KEY } from "../utils";
import Swal from "sweetalert2";

export const login = createAsyncThunk(
  "auth/login",
  async (formData, { rejectWithValue }) => {
    try {
      const res = await authService.login(formData);
      const data = await res.json();
      if (data.success) {
        localStorage.setItem(TOKEN_KEY, data?.data?.token);
        localStorage.setItem(AUTH_KEY, JSON.stringify(data?.data));
        return data?.data;
      }else{
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data?.message,
        });
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: JSON.parse(localStorage.getItem(AUTH_KEY)) || null,
    token: localStorage.getItem(TOKEN_KEY) || null,
    loading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(AUTH_KEY);
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.token = action.payload?.token;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
