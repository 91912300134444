import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { TokenCreate } from "../views/admin/components/TokenCreate";
import { PATIENT_URLS } from "../utils/constent";
import paymenterror from "../image/paymenterror.webp";

function PaymentResponse() {
  const [params] = useSearchParams();
  const message = params.get("message");
  //console.log(message);
  return (
    <div>
      {/* <TokenCreate doctor_id={2} urls={PATIENT_URLS} is_last={false} liveTest="yes"/> */}
      <div className="row justify-content-center w-100 py-4">
        <div className="col-md-5 text-center">
          <img src={paymenterror} className="d-block w-100" />
          <h2>Payment failed</h2>
          <h4 className="mb-4 text-danger">{message}</h4>
          <Link className="btn btn-primary btn-sm px-4 text-white pt-2" to="/">
            Return Back
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PaymentResponse;
