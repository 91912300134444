import React from "react";
import setting from "../image/setting.png";
import handupdown from "../image/handupdown.png";
import handheart from "../image/handheart.png";
import handflower from "../image/handflower.png";
export const Main = () => {
  return (
    <div>
      <div className="container pt-5">
        <p className="text-center mx-3">
          Mkite is a digital platform designed for both doctors and patients. It
          assists doctors with clinic management, particularly in the area of
          OPD (Outpatient Department) management. Patients can use the platform
          to track the live status of their scheduled appointments and doctor 's
          availability status.
        </p>

        <div className="row justify-content-between ">
          <div className="col-lg-3 col-md-6 text-center ">
            <img src={setting} alt="setting" />
            <p className="text-center">
              Track the estimated time of your appointment with doctor
            </p>
          </div>
          <div className="col-lg-3 col-md-6 text-center">
            <img src={handupdown} alt="handupdown" className="text-center" />
            <p className="text-center">Know the doctor available status</p>
          </div>
          <div className="col-lg-3 col-md-6 text-center">
            <img src={handflower} alt="handflower" />
            <p className="text-center">
              Keep safe your health-related document and get anytime access
            </p>
          </div>
          <div className="col-lg-3 col-md-6 text-center">
            <img src={handheart} alt="handheart" />
            <p className="text-center">Save time ,Save life</p>
          </div>
        </div>
      </div>
    </div>
  );
};
