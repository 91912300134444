import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const StartModel = ({ show, isStartButtonVisible, handleSubmit, modelToggle, isLoading }) => {
    return (
      <Modal show={show}>
        <Modal.Body>
          <h5>Are you sure to {isStartButtonVisible ? "stop" : "start"} consultation?</h5>
          <div className="mt-3 text-end">
            <Button variant="secondary" onClick={modelToggle}>
              No
            </Button>
            <button
              disabled={isLoading}
              className="btn btn-primary ms-2"
              type="submit"
              onClick={() => handleSubmit()}
            >
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}{" "}
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };