import React, { useEffect, useState } from "react";
import authService from "../services/authService";
import Swal from "sweetalert2";
import { Button, Card, Modal } from "react-bootstrap";
import { IoIosCall } from "react-icons/io";
import { PATIENT_URLS } from "../utils/constent";
import TokenCreateCard from "./TokenCreateCard";

export const DoctorAssociated = () => {
  const [doctors, setDoctors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showMsgModal, setShowMsgModal] = useState(false);
  const [isTrue, setIsTrue] = useState(false);
  const [doctorNotice, setDoctorNotice] = useState([]);

  const handelmodalcontain = () => {
    setIsTrue(!isTrue);
  };

  const getDoctors = async (category_id = null) => {
    const reqeustParam = { category_id: category_id };
    const resp = authService.doctorList(reqeustParam);
    resp
      .then(async (res) => {
        const resData = await res.json();
        if (resData.success === true) {
          const data = resData.data;
          setDoctors(data);
        } else {
          Swal.fire({
            icon: "error",
            title: `${resData.message}`,
            text: `Time - `,
          });
        }
      })
      .catch((err) => {});
  };

  const getCategory = async () => {
    const reqeustParam = { is_active: 1 };
    const resp = authService.categories(reqeustParam);
    resp
      .then(async (res) => {
        const resData = await res.json();
        if (resData.success === true) {
          const data = resData.data;
          setCategories(data);
        } else {
          Swal.fire({
            icon: "error",
            title: `${resData.message}`,
            text: `Time - `,
          });
        }
      })
      .catch((err) => {});
  };

  const getDoctorNotice = async (doctor_id = null) => {
    const reqeustParam = { doctor_id: doctor_id };
    const resp = authService.doctorNoticeListHome(reqeustParam);
    resp
      .then(async (res) => {
        const resData = await res.json();
        if (resData.success === true) {
          const data = resData.data;
          setDoctorNotice(data);
        } else {
          Swal.fire({
            icon: "error",
            title: `${resData.message}`,
            text: `Time - `,
          });
        }
      })
      .catch((err) => {});
  };

  const categoryChange = async (e) => {
    const category_id = e.target.value;
    getDoctors(category_id);
  };

  const refreshData = () => {
    setTimeout(() => {
      getDoctors();
      refreshData();
    }, 60000);
  };

  useEffect(() => {
    getDoctors();
    getCategory();
    refreshData();
    handelmodalcontain();
  }, []);

  const handleBookNowClick = (doctor) => {
    setSelectedDoctor(doctor);
    setIsTrue(true);
    setShowModal(true);
  };

  const handlePopupMsg = (doctor_id) => {
    setShowMsgModal(true);
    getDoctorNotice(doctor_id);
  };

  const handleCloseModal = () => {
    setSelectedDoctor(null);
    setShowModal(false);
    setIsTrue(false);
    setShowMsgModal(false);
  };

  return (
    <div>
      <div className="py-5" style={{ background: "#00a19b" }}>
        <h2 className="text-center text-white pb-4">Doctor Associated</h2>
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="text-right mb-3">
                <select className="form-control" onChange={categoryChange}>
                  <option value="">Select Category</option>
                  {categories.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            {doctors.map((doctor, index) => (
              <div className="col-lg-4" key={index}>
                <Card className="mb-4">
                  <div className="row align-items-center">
                    <div className="col-4 hover">
                      <img
                        src={doctor.profile || "../doct.png"}
                        alt="default doctor"
                        className="w-100 ms-2"
                        style={{
                          borderRadius: "10%",
                        }}
                      />
                    </div>
                    <div className="col-8 py-2">
                      <h5 className="py-1 my-0">{doctor.name}</h5>

                      <h6 className="py-0 my-0">
                        {doctor?.location?.registration_hospital_name}
                      </h6>
                      <div className="d-flex pt-1 pe-3 justify-content-between">
                        {doctor?.location?.mobile ? (
                          <p className="text-dark">
                            <IoIosCall /> {doctor?.location?.mobile}
                          </p>
                        ) : (
                          ""
                        )}

                        <p className="text-success small m-0 p-0">
                          {" "}
                          OPD Timing :{doctor?.shifts[0]?.from}
                        </p>
                        <p className="text-success small m-0 p-0">
                          {" "}
                          Fees :{doctor?.shifts[0]?.fees}
                        </p>
                      </div>
                      <p children="text-white m-0 p-0">
                        {doctor?.location?.category?.name}
                      </p>
                      {doctor?.online_booking == 1 ? (
                        <Button
                          className="btn-sm my-1  m-0 py-0"
                          style={{ backgroundColor: "#00a19a" }}
                          onClick={() => handleBookNowClick(doctor)}
                        >
                          Get Token
                        </Button>
                      ) : (
                        <Button
                          className="btn-sm my-1  m-0 py-0"
                          style={{ backgroundColor: "#00a19a" }}
                          onClick={() => handlePopupMsg(doctor.id)}
                        >
                          Get Token
                        </Button>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </div>
      <TokenCreateCard
        showModal={showModal}
        showMsgModal={showMsgModal}
        handleCloseModal={handleCloseModal}
        selectedDoctor={selectedDoctor}
        doctorNotice={doctorNotice}
        isTrue={isTrue}
        handelmodalcontain={handelmodalcontain}
      />
      <hr className="my-0" />
    </div>
  );
};
