import React from "react";
import Carousel from "react-bootstrap/Carousel";
import slider from "../image/slider.jpg";
import slider1 from "../image/slider1.jpg";
import slider2 from "../image/slider2.jpg";
import { Apponintment } from "./Appoinment";
import "../App.css";
export const Slider = () => {
  return (
    <div className="slider">
      <Carousel data-bs-theme="dark">
        <Carousel.Item>
          <img className="d-block w-100" src={slider} alt="slider" />
          <Carousel.Caption className="bg-light rounded d-none d-lg-block">
            <h3 className="text-website">
              Mkite is a real-time based digital platform,
            </h3>
            <p className="">
              that allows outpatients to track the time of their appointment
              with doctors and make sure they are saving their time due to not
              being in the waiting area for a long time.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={slider1} alt="slider1" />
          <Carousel.Caption className="bg-light rounded d-none d-lg-block">
            <h3 className="text-website">
              Mkite is a real-time based digital platform,
            </h3>
            <p className="">
              that allows outpatients to track the time of their appointment
              with doctors and make sure they are saving their time due to not
              being in the waiting area for a long time.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={slider2} alt="slider2" />
          <Carousel.Caption className="bg-light rounded d-none d-lg-block">
            <h3 className="text-website">
              Mkite is a real-time based digital platform,
            </h3>
            <p className="">
              that allows outpatients to track the time of their appointment
              with doctors and make sure they are saving their time due to not
              being in the waiting area for a long time.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className="apponintment-form">
        <div className="card py-5 py-lg-1">
          <Apponintment />
        </div>
      </div>
    </div>
  );
};
