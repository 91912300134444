import React, { useEffect, useState } from "react";
import { Dashboard } from "./Dashboard";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import { AUTH_TOKEN, BASE_URL } from "../../utils";
import axios from "axios";
import AddMoneyModel from "./components/AddMoneyModel";
import SubscriptionModel from "./components/SubscriptionModel";
import { useNavigate, useParams } from "react-router-dom";
import Head from "./components/Head";

export const Wallet = () => {
  const { subscribe } = useParams();
  const [list, setList] = useState([]);
  const [wallet, setWallet] = useState(0);
  const [subscription, setSubscription] = useState([]);
  const [subscriptionFees, setSubscriptionFees] = useState(0);
  const [isModel, setIsModel] = useState(false);
  const [isSubModel, setIsSubModel] = useState(false);
  const navigate = useNavigate();
  const fetchHistory = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/doctor/history`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      setList(response.data.data.history);
      setWallet(response.data.data.wallet);
      setSubscription(response.data.data.subscription);
      setSubscriptionFees(response.data.data.subscription_fees);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  useEffect(() => {
    fetchHistory();
    if (subscribe) {
      getPermission();
    }
  }, []);

  const modelAddMoneyToggle = () => {
    setIsModel(!isModel);
  };

  const subscriptionRenewalToggle = () => {
    navigate("../admin/plans/list");
    //setIsSubModel(!isSubModel);
  };

  const getPermission = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/role/permissions`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      const permissions = response.data.data.permissions;
      const role_permissions = response.data.data.role_permissions;
      localStorage.setItem(
        "role_permissions",
        JSON.stringify(role_permissions)
      );
      localStorage.setItem("menu", JSON.stringify(permissions));
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "60px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <Head />
          <div className="main-container my-3 px-lg-3 px-1">
            <div className="d-flex flex-wrap justify-content-around">
              <div className="card card-body me-2 mb-1">
                <div>
                  <h5 className={wallet < 5 ? "text-danger" : ""}>
                    Wallet Amount {wallet}₹
                  </h5>
                  <div className="text-end">
                    <button
                      className="btn btn-dark btn-sm py-1"
                      onClick={modelAddMoneyToggle}
                    >
                      Add Money
                    </button>
                  </div>
                </div>
              </div>
              <div className="card card-body mb-1">
                <div>
                  {subscription ? (
                    subscription.expired == false ? (
                      <h6>
                        Your subscription will expire on{" "}
                        {subscription?.end_date}
                      </h6>
                    ) : (
                      <h6 className="text-danger">
                        Your subscription has expired on{" "}
                        {subscription?.end_date} date, please renew immediately
                        to continue services
                      </h6>
                    )
                  ) : (
                    <h4>
                      You don't have subscription. Please subscribe to continue.
                    </h4>
                  )}
                  <div className="text-end">
                    <button
                      className="btn btn-dark btn-sm py-1"
                      onClick={subscriptionRenewalToggle}
                    >
                      {subscription ? "Renewal" : "Get Subscription"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-1">
              <div className="card card-body">
                <div className="d-flex justify-content-between">
                  <h4>Transaction History</h4>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mt-3">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Transaction Id</th>
                        <th>Amount</th>
                        <th>Type</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list &&
                        list.map((item) => (
                          <tr key={item.id}>
                            <td>{item.created_at}</td>
                            <td>{item.transactionId}</td>
                            <td>{item.amount}</td>
                            <td>{item.payment_type}</td>
                            <td
                              style={{ fontWeight: "700" }}
                              className={
                                item.status == 1
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {item.status == 1 ? "Success" : "Failed"}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddMoneyModel show={isModel} modelToggle={modelAddMoneyToggle} />
      <SubscriptionModel
        show={isSubModel}
        modelToggle={subscriptionRenewalToggle}
        subscriptionFees={subscriptionFees}
      />
    </div>
  );
};
