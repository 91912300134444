import React from "react";
import { Link } from "react-router-dom";
import {
  AiFillFacebook,
  AiFillGoogleCircle,
  AiFillInstagram,
  AiFillMail,
  AiFillMobile,
  AiOutlineNotification,
  AiOutlineTwitter,
  AiOutlineWhatsApp,
} from "react-icons/ai";

const Footer = () => {
  return (
    <div>
      <div
        className="container-fluid d-flex pt-4"
        style={{ backgroundColor: "#00a19a" }}
      >
        <div className="row justify-content-between">
          <div className="col-lg-6">
            <div className="pageAbout">
              <h1 className="text-website text-white">MKite</h1>
              <p className="text-white">
                Mkite is a real-time based digital platform that allows
                outpatients to track the time of their appointment with doctors
                and make sure they are saving their time due to not being in the
                waiting area for a long time.
              </p>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="content">
              <h5 className="text-website text-white">Quick Links</h5>
              <ul className="list-unstyled text-white text-decoartion-none">
                <li>
                  <Link
                    className="text-decoration-none text-white"
                    to="aboutus"
                  >
                    About US
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none text-white"
                    to="contact"
                  >
                    Contact US
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none text-white"
                    to="term-condition"
                  >
                    Terms & Conditions
                  </Link>
                </li>

                <li>
                  <Link
                    className="text-decoration-none text-white"
                    to="privacy-policy"
                  >
                    Privacy & Policy
                  </Link>
                </li>

                <li>
                  <Link
                    className="text-decoration-none text-white"
                    to="refund-policy"
                  >
                    Refund Polocy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="content">
              <h5 className="text-website text-white">Stay In Touch</h5>

              <ul className="list-unstyled">
                <li>
                  <Link
                    className="text-decoration-none text-white"
                    to="/doctorassociated"
                  >
                    Get Token
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none text-white" to="/work">
                    How it Works
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none text-white"
                    to="articles"
                  >
                    Articles
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    className="text-decoration-none text-white nav-link"
                    to="feedback"
                  >
                    Feedback
                  </Link>
                </li>
                {/* <li>
                  <Link className="text-decoration-none text-white" to="#">
                    <AiFillMobile 
                       style={{ marginLeft: "-1rem", marginRight: "1rem" }}
                    />
                    <a href="tel:9997311408">+919997311408</a>
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none text-white" to="#">
                    <AiFillMail
                      
                       style={{ marginLeft: "-1rem", marginRight: "1rem" }}
                  />
                    <a href="mailto:info@mkite.com">info@mkite.com</a>
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none text-white" to="#">
                    <AiOutlineNotification
                       style={{ marginLeft: "-1rem", marginRight: "1rem" }}
                    />
                    Sanjay Place Agra Uttar Pradesh India - 282002
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="content footer">
              <h5 className="text-website text-white">Connect With</h5>

              <ul className="list-unstyled">
                <li>
                  <Link
                    className="text-decoration-none text-white"
                    to="https://wa.me/916395819007"
                    target="_blank"
                  >
                    <AiOutlineWhatsApp
                      style={{ marginLeft: "5px", marginRight: "5px" }}
                    />
                    WhatsApp
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none text-white"
                    to="https://www.facebook.com/profile.php?id=61557107694303&mibextid=ZbWKwL"
                    target="_blank"
                  >
                    <AiFillFacebook
                      style={{ marginLeft: "5px", marginRight: "5px" }}
                    />
                    Facebook
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none text-white"
                    to="https://www.instagram.com/mkite.in?utm_source=qr&igsh=MWtjMWdhdGJ0Z2E3aA=="
                    target="_blank"
                  >
                    <AiFillInstagram
                      style={{ marginLeft: "5px", marginRight: "5px" }}
                    />
                    Instagram
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none text-white"
                    to="https://g.co/kgs/NQnttaS"
                    target="_blank"
                  >
                    <AiFillGoogleCircle
                      style={{ marginLeft: "5px", marginRight: "5px" }}
                    />
                    Review us on Google
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr className="my-0" />
      <div
        className="text-center py-3 text-white"
        style={{ background: "rgb(0 161 154)" }}
      >
        Copyright © 2024-2025, mkite all Right Reserved. Provided by A n enterprises powered by mkite healthtech pvt Ltd
        {/* & Designed By <a className="text-decoration-none text-white" href="https://www.zaibainfotech.com/" target="_blank">Zaiba Infotech</a> */}
      </div>
    </div>
  );
};

export default Footer;
