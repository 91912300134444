import React, { useState } from "react";
import authService from "../services/authService";
import Swal from "sweetalert2";
export const Apponintment = () => {
  const [formData, setFormData] = useState({
    mobile: "",
    token: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const reqeustParam = formData;
    const resp = authService.fatchToken(reqeustParam);
    resp
      .then(async (res) => {
        const resData = await res.json();
        if (resData.success == true) {
          const data = resData.data;
          Swal.fire({
            icon: "success",
            title: `Doctor Name - : ${data.doctor.name}`,
            text: `Time - ${data.expected_time}`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `${resData.message}`,
            text: `Time - `,
          });
        }
      })
      .catch((err) => {});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <div>
        <form className="p-3 py-lg-4" action="./tokenDetails">
          <h4 className="mb-4 fs-2">Appointment Time Tracker</h4>
          <div className="d-flex flex-column bg-light">
            <input
              name="mobile"
              type="number"
              maxLength={10}
              minLength={10}
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              className="form-control bg-light text-dark mt-3"
              placeholder="Enter Mobile Number"
              required
            />
            <input
              type="number"
              name="token"
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              className="form-control bg-light text-dark mt-3"
              placeholder="Enter Token Number"
              required
            />
            <button type="submit" className="btn btn-info mt-3">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
