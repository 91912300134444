import React, { useEffect, useState } from "react";
import { ReviewRating } from "./ReviewRating";
import authService from "../services/authService";
import Swal from "sweetalert2";
import { Button, Modal } from "react-bootstrap";

export const Articles = () => {
  const [articals, setArticals] = useState([]);
  const [viewArtical, setViewArtical] = useState([]);
  const [show, setShow] = useState(false);

  const ViewDetails = ({ item, setShow }) => {
    return (
      <Modal show={show}>
        <Modal.Body>
          <div className="p-0">
            <img src={item.image} width="100%" />
            <h6 className="pt-2">{item.title}</h6>
            <p>{item.description}</p>
          </div>
          <div className="mt-2 text-end">
            <Button variant="secondary" className="py-0" onClick={() => setShow()}>
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const getArticals = async () => {
    const reqeustParam = {};
    const resp = authService.getArticals(reqeustParam);
    resp
      .then(async (res) => {
        const resData = await res.json();
        if (resData.success === true) {
          const data = resData.data;
          setArticals(data);
        }
      })
      .catch((err) => {});
  };

  const viewDetails = (data) => {
    setViewArtical(data);
    setShow(true);
  };

  useEffect(() => {
    getArticals();
  }, []);

  return (
    <div>
      <div className="articleContainer py-5">
        <h2 className="text-white text-center">Articles</h2>
        <div className="container">
          <div className="row pt-4">
            {articals.map((item, index) => (
              <div className="col-lg-4 mt-4" key={index}>
                <div className="card">
                  <div className="card-body p-0">
                    <img src={item.image} width="100%" />
                    <h6 className="p-2">
                      {item.title} &nbsp;
                      <small
                      role="button"
                        className="text-primary"
                        onClick={() => viewDetails(item)}
                      >
                        Read More...
                      </small>
                    </h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <ReviewRating />
      <ViewDetails item={viewArtical} show={show} setShow={setShow} />
    </div>
  );
};
