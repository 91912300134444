import React from "react";

export const RefundPolicy = () => {
  return (
    <>
      <hr className="mt-0 pt-0" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h2 className="text-website text-center pt-5">
              Cancellations and Refunds
            </h2>
            <h4 className="text-center pt-3 mx-lg-5">
              This policy outlines the process to cancellation or request a
              refund for services you purchase through the Platform.
            </h4>
            <div className="row mb-5">
              <div className="col-lg-12 col-md-12 mt-3 mt-lg-5">
                <div id="policy" className="py-5">
                  <h5 className="fs-2 text-website mb-4">Cancellations:</h5>
                  <ul>
                    <li className="pb-3" style={{ lineHeight: "1.8em" }}>
                      You can cancel your appointment if the request is
                      submitted at least 2 hours before the scheduled start time
                      of OPD or consultation.
                    </li>
                    <li className="pb-3" style={{ lineHeight: "1.8em" }}>
                      Cancellations cannot be guaranteed if consultation has
                      already begun, regardless of whether any other patient has
                      started to take consultation
                    </li>
                    <li className="pb-3" style={{ lineHeight: "1.8em" }}>
                      You can cancel your token and ask for full refund In case
                      of consultation has been cancelled from Doctor's side
                    </li>
                  </ul>
                  <h5 className="fs-2 text-website mb-4">Refunds : </h5>
                  <ul>
                    <li className="pb-3" style={{ lineHeight: "1.8em" }}>
                      If your cancellation request is approved, It would take
                      5-6 business days to get the refund amount credited to
                      customer's bank account.
                    </li>
                    <li className="pb-3" style={{ lineHeight: "1.8em" }}>
                      In case of complaints regarding the service: o Please
                      contact our customer service team within 3 days of your
                      appointment. We will investigate your complaint and
                      determine an appropriate resolution, which may include a
                      full or partial refund.
                    </li>
                  </ul>
                  <h5 className="fs-2 text-website mb-4">How to send request to cancellation request :</h5>
                  <p>You need to send request for cancellation through email I'd mentioned below <a href="mailto:info@mkite.in">info@mkite.in</a> with below details.</p>
                  <p>Name           :</p>
                  <p>Mobile         :</p>
                  <p>Token No       :</p>
                  <p>Doctor Name    :</p>
                  <p>Date           :</p>
                  <h4>-- OR -- </h4>
                  <p>Name           :</p>
                  <p>Transaction id :</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
