import React, { useState } from "react";
import Swal from "sweetalert2";
import Header from "./Header";
import { Dashboard } from "./Dashboard";
import { AUTH_TOKEN, baseURl } from "../../utils";
import axios from "axios";
import Head from "./components/Head";

export const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    current_password: "",
    new_password: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(!loading);
    try {
      await axios
        .post(`${baseURl}/changePassword`, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        })
        .then((response) => {
          setLoading(false);
          if (response.data.success) {
            Swal.fire({
              icon: "success",
              title: "success",
              text: response.data.message,
              bodydisable: false,
            });
            setFormData({
              current_password: "",
              new_password: "",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response?.data?.message,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          // Use SweetAlert to display an error message
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error?.response?.data?.message,
          });
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to verify OTP. Please try again.",
      });
      return false;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "80px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <Head />
          <div className="main-container my-3 px-lg-3 px-1">
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="card card-body">
                  <form className="" onSubmit={handleSubmit}>
                    <div className="py-4">
                      <h4 className="mb-2 fs-2 text-center text-website">
                        Change Password
                      </h4>
                      <input
                        type="text"
                        name="current_password"
                        value={formData.current_password}
                        onChange={handleInputChange}
                        className="form-control bg-light text-dark mt-3"
                        placeholder="Please enter old password"
                        required
                      />
                      <input
                        type="text"
                        name="new_password"
                        value={formData.new_password}
                        onChange={handleInputChange}
                        className="form-control bg-light text-dark mt-3"
                        placeholder="Please enter new password"
                        required
                      />
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-success mt-3 px-5"
                          disabled={loading}
                        >
                          {loading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
