import React, { useEffect, useState } from "react";
import authService from "../services/authService";
import Swal from "sweetalert2";
import Feedback from "../components/Feedback";
import Review from "../components/Review";
import { TimeDetials } from "./TimeDetails";

export const TokenDetail = () => {
  const params = new URLSearchParams(window.location.search);
  const [tokenData, setTokenData] = useState([]);
  const [patientData, setPatientData] = useState([]);
  const [list, setList] = useState([]);
  const [isModel, setIsModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getTokenDetail = async () => {
    const reqeustParam = {
      token: params.get("token"),
      mobile: params.get("mobile"),
    };
    setIsLoading(true)
    const resp = authService.fatchToken(reqeustParam);
    resp
      .then(async (res) => {
        const resData = await res.json();
        if (resData.success == true) {
          const data = resData.data;
          setTokenData(data.details);
          setPatientData(data.details.patient);
          setList(data.notices);
          setIsLoading(false)
        } else {
          Swal.fire({
            icon: "error",
            title: `${resData.message}`,
            text: `Time - `,
          });
        }
      })
      .catch((err) => {});
  };

  const modelToggle = () => {
    setIsModel(!isModel);
  };

  const refreshAgain = () => {

    getTokenDetail();
  }

  const refreshData = () => {
    setTimeout(() => {
      getTokenDetail();
      refreshData();
    }, 60000);
  };
  useEffect(() => {
    getTokenDetail();
    refreshData();
  }, []);
  return (
    <>
      <div>
        <div className="app-card-article mb-5">
          <div className="d-flex justify-content-center">
            <h2 className="bg-success w-100 py-2 text-center text-white mb-0">
              TOKEN DETAILS
            </h2>
          </div>
          <div className="d-flex justify-content-center bg-danger w-100 py-2 ">
            <div className="container">
              <h6 className="text-white" style={{fontSize:"13px"}}>
                Note :- Please note that the appointment time may vary slightly
                based on the Doctor's availability and patient flow. We will do
                our best to keep you updated and minimize any wait time.
              </h6>
            </div>
          </div>
          <div className="">
            {tokenData ? (
              <>
                <div className="container-fluid">
                  <div className="row pt-4 justify-content-center">
                    <div className="col-lg-4 mb-4">
                      <div className="">
                        <h4 className="pt-3 mb-0 mb-0">Notice</h4>
                        <hr />
                        <div className="">
                          {list.map((item, index) => (
                            <div
                              className={`p-1 h6 ${
                                index % 2 == 0
                                  ? "bg-danger text-white"
                                  : "bg-warning"
                              }`}
                              key={item.id}
                            >
                              {item?.message}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 my-2">
                      <div className="">
                        <div className="">
                          <div className="d-flex justify-content-center">
                            <div
                              className=" text-white text-center p-1"
                              style={{
                                backgroundColor: "#7ba1da",
                                width: "200px",
                                height: "200px",
                                borderRadius: "15px",
                              }}
                            >
                              <button className="btn btn-sm bg-white mt-4">
                                Previous TokenNo
                              </button>
                              {tokenData.previous_token &&
                                (tokenData.previous_token?.status == null ? (
                                  <h4 style={{ fontSize: "5rem" }}>
                                    {tokenData.previous_token?.token_number}
                                  </h4>
                                ) : (
                                  <div>
                                    <h4
                                      className="mt-2 pt-2 text-capitalize"
                                      style={{ fontSize: "1.5rem" }}
                                    >
                                      {tokenData.previous_token?.status} Token
                                    </h4>
                                    <p>
                                      T-{tokenData.previous_token?.token_number}
                                    </p>
                                  </div>
                                ))}
                            </div>
                            <div
                              className=" text-white text-center p-1"
                              style={{
                                backgroundColor: "green",
                                width: "200px",
                                height: "230px",
                                margin: "-15px 0px",
                                borderRadius: "15px",
                              }}
                            >
                              <button className="btn btn-sm bg-white mt-4">
                                Current Token No.
                              </button>
                              {tokenData.current_token != "BRK" &&
                              tokenData.current_token != null ? (
                                tokenData.current_token &&
                                (tokenData.current_token?.status == null ? (
                                  <h4 style={{ fontSize: "5rem" }}>
                                    {tokenData.current_token.token_number}
                                  </h4>
                                ) : (
                                  <div>
                                    <h4
                                      className="mt-2 pt-2 text-capitalize"
                                      style={{ fontSize: "1.5rem" }}
                                    >
                                      {tokenData.current_token?.status} Token
                                    </h4>
                                    <p>
                                      T-{tokenData.current_token.token_number}
                                    </p>
                                  </div>
                                ))
                              ) : (
                                <h4
                                  className="mt-4 pt-2"
                                  style={{
                                    fontSize: "1.5rem",
                                    fontFamily: "cursive",
                                  }}
                                >
                                  {tokenData.next_token &&
                                  tokenData.next_token.token_number != 1
                                    ? "It's Break"
                                    : "Waiting for start"}
                                </h4>
                              )}
                            </div>
                            <div
                              className=" text-white text-center p-1"
                              style={{
                                backgroundColor: "#56565a",
                                width: "200px",
                                height: "200px",
                                borderRadius: "15px",
                              }}
                            >
                              <button className="btn btn-sm bg-white mt-4">
                                Next Token No.
                              </button>
                              {tokenData.next_token &&
                                (tokenData.next_token?.status == null ? (
                                  <h4 style={{ fontSize: "5rem" }}>
                                    {tokenData.next_token?.token_number}
                                  </h4>
                                ) : (
                                  <div>
                                    <h4
                                      className="mt-2 pt-2 text-capitalize"
                                      style={{ fontSize: "1.5rem" }}
                                    >
                                      {tokenData.next_token?.status} Token
                                    </h4>
                                    <p>
                                      T-{tokenData.next_token?.token_number}
                                    </p>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 mb-2">
                      <h4 className="pt-3 mb-0 mb-0">Token Detail</h4>
                      <hr />
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Patient Name</th>
                            <th>{tokenData?.patient?.name}</th>
                          </tr>
                          <tr>
                            <th>Token Number</th>
                            <th>{tokenData?.token_number}</th>
                          </tr>
                          <tr>
                            <th>Token Status</th>
                            <th>
                              {tokenData?.is_type == "Skip"
                                ? "Skipped"
                                : tokenData?.is_type}
                            </th>
                          </tr>

                          <tr>
                            <th colSpan={2}>
                              <button
                                className="btn btn-link"
                                onClick={modelToggle}
                              >
                                View Time Details and More
                              </button>
                            </th>
                          </tr>
                        </thead>
                      </table>
                      <h4 className="pt-3 mb-0 mb-0">Doctor Detail</h4>
                      <hr />
                      <table className="table table-bordered">
                        <thead>
                          {tokenData?.shift?.hospital_name && (
                            <tr>
                              <th>Hospital Name</th>
                              <th>{tokenData?.shift?.hospital_name}</th>
                            </tr>
                          )}
                          {tokenData?.shift?.doctor_name && (
                            <tr>
                              <th>Doctor Name</th>
                              <th>{tokenData?.shift?.doctor_name}</th>
                            </tr>
                          )}
                          {/* <tr>
                        <th>Name</th>
                        <th>{tokenData?.shift?.name}</th>
                      </tr> */}
                          {tokenData?.shift?.mobile && (
                            <tr>
                              <th>Mobile No.</th>
                              <th>{tokenData?.shift?.mobile}</th>
                            </tr>
                          )}
                          <tr>
                            <th>Doctor Address</th>
                            <th>
                              {tokenData?.shift.address && (
                                <>{tokenData?.shift.address}, </>
                              )}

                              {tokenData?.shift.map_location && (
                                <a target="_blank" className="text-decoration-none" href={tokenData?.shift?.map_location}>
                                  <i>View on Map</i>
                                </a>
                              )}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="pt-4">
                  <div className="container-fluid">
                    <h2 className="text-center mb-4 pb-3">
                      Share your review with MKITE
                    </h2>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <Feedback patientData={patientData} />
                      </div>
                      <div className="col-md-6 text-center">
                        <Review doctor_id={0} is_type="mkite" />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <TimeDetials
            show={isModel}
            modelToggle={modelToggle}
            expected_time={tokenData?.expected_time}
            is_type={tokenData?.is_type}
            left_time={tokenData?.left_time}
            patientTotal={tokenData?.patientTotal}
            patientIn={tokenData?.patientIn}
            refreshAgain={refreshAgain}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};
