import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal } from "react-bootstrap";

export const FeeTypeModel = ({
  show,
  handleSubmit,
  handleInputChange,
  formData,
  modelToggle,
  isLoading,
  mode
}) => {
  return (
    <div>
      <Modal className="modal" show={show}>
        <Modal.Header>
          <Modal.Title className="h6">{mode} Fees</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 mb-3">
                <label className="mb-1">Title</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="title"
                  type="text"
                  value={formData.title}
                />
              </div>
              <div className="col-12 mb-3">
                <label className="mb-1">Fees</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="amount"
                  type="number"
                  value={formData.amount}
                />
              </div>

              <div className="col-12 mb-3">
                <label className="mb-1">Status</label>
                <select
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="is_active"
                  type="text"
                  value={formData.is_active}
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12 my-3 text-end">
              <Button variant="secondary" onClick={modelToggle}>
                Close
              </Button>
              <button
                disabled={isLoading}
                className="btn btn-primary ms-2"
                type="submit"
              >
                {isLoading && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}{" "}
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
        <hr />
      </Modal>
    </div>
  );
};
