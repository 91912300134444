import React from "react";
import { Button, Modal } from "react-bootstrap";
import { BiRefresh } from "react-icons/bi";

export const TimeDetials = ({
  show,
  modelToggle,
  expected_time,
  left_time,
  patientTotal,
  patientIn,
  refreshAgain,
  isLoading
}) => {
  return (
    <div>
      <Modal className="modal-dialog-centered" show={show}>
        <Modal.Header>
          <Modal.Title className="h5">Time Details</Modal.Title>
          <Button className="ms-3" variant="secondary" onClick={modelToggle}>
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <tbody>
              <tr>
                <th>Expected Time</th>
                <th>: {expected_time}</th>
              </tr>
              <tr>
                <th>Left Time</th>
                <th>: {left_time}</th>
              </tr>
              <tr>
                <th>No. of Patient (before you)</th>
                <th>: {patientTotal}</th>
              </tr>
              <tr>
                <th>Arrived at Clinic</th>
                <th>: {patientIn}</th>
              </tr>
            </tbody>
          </table>
          <a className="d-flex justify-content-end text-decoration-none" onClick={() => refreshAgain()}>
            {isLoading ? (
              <span className="spinner-border spinner-border-sm mt-1"></span>
            ) : (
              <BiRefresh size={24}/>
            )}
            &nbsp;Refresh
          </a>
          <hr />
          <h5 className="text-danger text-center">समय में बदलाव संभव है।</h5>
        </Modal.Body>
      </Modal>
    </div>
  );
};
