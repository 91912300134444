import React from "react";
import { Button, Modal } from "react-bootstrap";
import { PATIENT_URLS } from "../utils/constent";
import { TokenCreate } from "../views/admin/components/TokenCreate";

const TokenCreateCard = ({
  handelmodalcontain,
  showModal,
  showMsgModal,
  handleCloseModal,
  selectedDoctor,
  doctorNotice,
  isTrue,
  shiftId
}) => {
  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <div className="small">
              Book Appointment with {selectedDoctor?.name}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isTrue ? (
            <div>
              <p>
                <span className="text-danger fs-5">*</span> एक टोकन नंबर केवल एक
                ही मरीज के लिए मान्य है यदि आप दो मरीजों को दिखाना चाहते है तो
                कृपया दोनो के लिए अलग टोकन प्राप्त करें{" "}
              </p>
              <p>
                <span className="text-danger fs-5">*</span> बुकिंग करने से पहले
                सुनिश्चित कर ले कि आपने या आपके परिचित ने आपसे पहले टोकन बुकिंग
                तो नही कर ली, एक ही मरीज के लिए एक ही अथवा एक से अधिक व्यक्तियों
                द्वारा एक ही नाम/ मोबाइल नंबर से अथवा अलग अलग नामों/मोबाइल नंबर
                से टोकन प्राप्त करने पर सेवा शुल्क वापिस नही किया जाएगा,
              </p>
              <p>
                <span className="text-danger fs-5">*</span> बुकिंग करते
                समय/पहले/बाद में यदि आपको आपके appointment से संबंधित कोई भी
                जानकारी अगर प्रदान की जा रही हैं तो वह सिर्फ अनुमानित है और उसमे
                बदलाव संभव है।
              </p>
              <p>
                <span className="text-danger fs-5">*</span> यह सिर्फ अपॉइंटमेंट
                के लिए टोकन की बुकिंग है ना कि अपॉइंटमेंट की बुकिंग अतः इसके लिए
                दिया गया शुल्क डॉक्टर की फीस से पूर्णत अलग है{" "}
              </p>
              <p>
                <span className="text-danger fs-5">*</span> टोकन बुकिंग करने के
                बाद यदि किसी कारणवश डॉक्टर अपनी सेवा देने (पूरे दिन के लिए)में
                असमर्थ होता है तो उसके लिए mkite किसी भी प्रकार से जिम्मेदार
                नहीं होगा
              </p>
              <p>
                <span className="text-danger fs-5">*</span> डॉक्टर द्वारा यदि
                किसी कारण वश सेवा देने में असमर्थ होने की दशा में आपके द्वारा
                दिया गया शुल्क वापिस किया जाएगा
              </p>
              <p>
                <span className="text-danger fs-5">*</span> डॉक्टर द्वारा देरी
                से सेवा आरंभ करने/मरीज के द्वारा देरी से पहुंचने/ना पहुंचने/नंबर
                निकल जाने अथवा डॉक्टर की सेवा ना लेने/बाद में लेने की दशा में
                सेवा शुल्क वापिस नही किया जाएगा
              </p>
              <Button className="btn-dark" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button onClick={handelmodalcontain} className="ms-3 btn-info">
                Continue
              </Button>
            </div>
          ) : (
            <TokenCreate
              doctor_id={selectedDoctor?.id}
              urls={PATIENT_URLS}
              is_last={false}
              payment="yes"
              shiftId={shiftId}
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showMsgModal} onHide={handleCloseModal}>
        <Modal.Body>
          <p>
            Online token booking is not available for now. Please contact the
            clinic directly to get token.
          </p>
          {doctorNotice &&
            doctorNotice.map((item, index) => (
              <p
                className={`p-1 h6 ${
                  index % 2 == 0 ? "bg-danger text-white" : "bg-warning"
                }`}
                key={item.id}
              >
                {item?.message}
              </p>
            ))}
          <div className="mt-3 d-flex justify-content-end">
            <Button className="btn-dark" onClick={handleCloseModal}>
              close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TokenCreateCard;
