import React, { useEffect, useState } from "react";
import { Dashboard } from "./Dashboard";
import axios from "axios";
import { AUTH_TOKEN, BASE_URL } from "../../utils";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import Header from "./Header";
import { StaffModel } from "./components/StaffModel";
import { useSelector } from "react-redux";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { IoMdAddCircleOutline } from "react-icons/io";
import Head from "./components/Head";

export const Staff = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState("Add");
  const [list, setList] = useState([]);
  const [isModel, setIsModel] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    role_id: "",
  });
  const [permissions, setPermissions] = useState({
    add: 0,
    edit: 0,
    delete: 0,
  });
  const userPer = JSON.parse(localStorage.getItem('role_permissions') || "[]");
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      staff_id: formData.staff_id,
      name: formData.name,
      mobile: formData.mobile,
      email: formData.email,
      password: formData.password,
      role_id: formData.role_id,
    };
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/doctor/employee/add`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      if (response.status === 200) {
        setFormData({ name: "", mobile: "", email: "", password: "", role_id: "" });
        setIsModel(false);
        fetchList();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response.data.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchList = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/doctor/employee/list`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      if (response.status === 200) {
        setList(response.data.data);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const editStaff = (staff) => {
    setFormData({
      staff_id: staff.id,
      name: staff.name,
      mobile: staff.mobile,
      email: staff.email,
      role_id: staff.role_id,
    });
    setMode("Edit");
    setIsModel(true);
  };

  const deleteStaff = async (staff_id) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/doctor/employee/delete`,
        { staff_id: staff_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      if (response.status === 200) {
        fetchList();
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const modelToggle = () => {
    setIsModel(!isModel);
    setMode("Add");
    setFormData({ name: "", mobile: "", email: "", password: "", role_id: "" });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    fetchList();
    if(userPer){
        const addPermission = userPer.some((perm) => perm.id === 32);
        const editPermission = userPer.some((perm) => perm.id === 33);
        const deletePermission = userPer.some((perm) => perm.id === 34);
// console.log(addPermission);
        setPermissions({ add: addPermission, edit: editPermission, delete: deletePermission });
    }
  }, []);
  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "60px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
        <Head />
          <div className="main-container my-3 px-lg-3 px-1">
            <div className="d-flex justify-content-center mt-3">
              <div className="card card-body">
                <div className="d-flex justify-content-between">
                  <h4>Staff List</h4>
                  {permissions.add > 0 && (
                    <button
                      className="btn btn-primary pb-2"
                      onClick={modelToggle}
                    >
                      <IoMdAddCircleOutline /> Add Staff
                    </button>
                  )}
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mt-3">
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((item) => (
                        <tr key={item.id}>
                          <td>{item.first_name}</td>
                          <td>{item.name}</td>
                          <td>{item.mobile}</td>
                          <td>{item.email}</td>
                          <td>{item?.role?.name}</td>
                          <td>
                            <div className="d-flex">
                              {permissions.edit > 0 && (
                                <button
                                  onClick={() => editStaff(item)}
                                  className="btn btn-warning btn-sm ml-2"
                                >
                                  <FaRegEdit />
                                </button>
                              )}
                              &nbsp;
                              {permissions.delete > 0 && (
                                <button
                                  onClick={() => deleteStaff(item.id)}
                                  className="btn btn-danger btn-sm mr-2"
                                >
                                  <MdDeleteOutline />
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StaffModel
        show={isModel}
        handleSubmit={handleSubmit}
        formData={formData}
        handleInputChange={handleInputChange}
        modelToggle={modelToggle}
        isLoading={isLoading}
        mode={mode}
      />
    </div>
  );
};
