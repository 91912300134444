import React from "react";
import { USER_DETAILS } from "../../../utils";

const Head = () => {
  return (
    <div>
      <div className="app-greeting px-4 d-none d-lg-block mt-2 pt-1">
        <div className="d-flex justify-content-end align-items-center">
          <div className="pe-1">
            <div className="avatr">
              <div className="bg-secondary rounded-circle px-2">
                <span className="fs-4 text-uppercase">
                  <b>{USER_DETAILS.name.slice(0, 1)}</b>
                </span>
              </div>
            </div>
          </div>
          <h5 className="nameHeading mt-2">
            <span className="ng-star-inserted">
              {USER_DETAILS.user_type != "doctor" ? (
                <>({USER_DETAILS.doctor.name})</>
              ) : (
                ""
              )}
              {USER_DETAILS.name}
            </span>
          </h5>
        </div>
      </div>
      <hr className="mt-3 d-none d-lg-block" />
    </div>
  );
};

export default Head;
