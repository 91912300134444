import React from 'react'
import { DoctorRegister } from '../views/admin/DoctorRagiter'


export const Ragister = () => {
  return (
    <div>
     <DoctorRegister />
    </div>
  )
}
