import { baseURl } from ".";

export const DOCTOR_URLS = {
  addToken: `${baseURl}/doctor/patient/add`,
  mobileCheck: `${baseURl}/doctor/patient/mobile-check`,
  lastToken: `${baseURl}/doctor/patient/last-token`,
  listShift: `${baseURl}/doctor/listShift`,
};

export const PATIENT_URLS = {
  addToken: `${baseURl}/online-booking`,
  mobileCheck: `${baseURl}/mobile-check`,
  lastToken: `${baseURl}/last-token`,
  listShift: `${baseURl}/listShift`,
};

