import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Dashboard } from "./Dashboard";
import Head from "./components/Head";
import { Col } from "react-bootstrap";
import axios from "axios";
import { AUTH_TOKEN, baseURl } from "../../utils";
import Utils from "../../utils/Utils";
import { Link } from "react-router-dom";

const Message = () => {
  const [list, setList] = useState([]);

  const fatchList = async () => {
    try {
      const response = await axios.post(
        `${baseURl}/doctor/message/list`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        setList(response.data.data);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  useEffect(() => {
    fatchList();
  }, []);
  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "60px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <div className="main-container">
            <hr className="d-block d-lg-none" />
            <Head />
            <div className="reportContainer my-3 ps-3 pe-4 pe-2">
              <div className="">
                <div className="my-4">
                  <div className="d-flex flex-wrap justify-content-between">
                    <h4>Messages List</h4>
                    <Col className="text-end mb-2" xs={24} sm={24} md={6}>
                      <Link className="btn btn-warning btn-sm text-white" to="../admin/message/send">
                        Send Message
                      </Link>
                    </Col>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered mt-3 w-100">
                      <thead>
                        <tr>
                          <th>S.N.</th>
                          <th>Date</th>
                          <th>Title</th>
                          <th>Type</th>
                          <th>Count</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((item, index) => (
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.created_at ?? ""}</td>
                            <td>{item.template.title ?? ""}</td>
                            <td>{item.template_type ?? ""}</td>
                            <td>{item.total ?? ""}</td>
                            <td>₹{item.amount ?? ""}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
