import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { BiStar } from "react-icons/bi";
import authService from "../services/authService";
import testImg from "../image/testimonial.jpg";

const Review = ({ doctor_id = null, is_type = "mkite" }) => {
  const [testimonial, setTestimonial] = useState([]);
  const getTokenDetail = async () => {
    try {
      const reqeustParam = { doctor_id: doctor_id, type: is_type };
      const resp = await authService.fatchReview(reqeustParam);
      const resData = await resp.json();
      if (resData.success) {
        setTestimonial(resData.data);
      }
    } catch (error) {
      console.error("Error fetching testimonials:", error);
    }
  };

  useEffect(() => {
    getTokenDetail();
  }, []);

  return (
    <div>
    {testimonial.length > 0 ? (
      <div className="py-5 px-3" style={{ background: "#00a19a", borderRadius: "29px" }}>
        <h4 className="text-center py-4 h2 text-white" style={{ fontWeight: 600, letterSpacing: '1.8px', lineHeight: '18px' }}>
          Our Testimonials
        </h4>
        <Carousel interval={1000}>
          {testimonial.map((c, index) => (
            <Carousel.Item key={index} interval={2000}>
              <div
                style={{
                  paddingTop:"30px",
                  color: "white",
                  textAlign: "center",
                  height: "245px",
                  borderRadius: "15px",
                  width: "100%",
                }}
              >
                <p className="pt-3">{c.review}</p>
                {[1, 2, 3, 4, 5].map((value) => (
                  <BiStar
                    key={value}
                    size={27}
                    color={c.rating >= value ? "orange" : "black"}
                    role="button"
                  />
                ))}
                <div className="text-center pt-3">
                  <p style={{ color: "black", fontWeight: 600 }}>{c.name}</p>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    ) : (
      <img src={testImg} className="w-100" alt="Testimonial" />
    )}
  </div>
  );
};

export default Review;
