import React, { useState } from "react";
import authService from "../../services/authService";
import { Dashboard } from "../../views/admin/Dashboard";
import { useNavigate } from "react-router-dom";

export const DoctorStep3 = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    totalshift: "",
    from: "",
    to: "",
    max_patient: 0,
    avg_consult_time:0,
    weekoff: false,
    weekoffTuesday: false,
    weekoffWednesday: false, // Fixed typo in property name
    weekoffThursday: false, // Fixed typo in property name
    weekoffFriday: false,
    weekoffSaturday: false,
    weekoffSunday: false, // Fixed typo in property name
    break_duration: "",
    break_from: "",
    break_to: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestParam = formData;
    const resp = await authService.ragister3(requestParam);
    try {
      const data = await resp.json();
      if(data.status == 200){
        navigate('admin/dashboard');
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="dashboard">
      <div className="row">
        <div className="col-md-2 px-0">
          <Dashboard />
        </div>
        <div
          className="col-md-10 px-0"
          style={{
            borderLeft: "1px solid gray",
          }}
        >
          <div className="main-container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <form className="w-100" onSubmit={handleSubmit}>
                  <div className="d-flex flex-column bg-light px-2 py-5">
                    <h4 className="py-2 fs-2 text-center text-website">
                      Register
                    </h4>
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="py-2">Total Shift</label>
                        <input
                          type="number"
                          name="totalshift"
                          value={formData.totalshift}
                          onChange={handleInputChange}
                          className="form-control bg-light text-dark mt-3"
                          placeholder="Name"
                          required
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="py-2">Open Time</label>
                        <input
                          type="time"
                          name="from"
                          value={formData.from}
                          onChange={handleInputChange}
                          className="form-control bg-light text-dark mt-3"
                          required
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="py-2">Closing Time</label>
                        <input
                          type="time"
                          name="to"
                          value={formData.to}
                          onChange={handleInputChange}
                          className="form-control bg-light text-dark mt-3"
                          required
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="py-2">Maximum Patient</label>
                        <input
                          type="number"
                          name="max_patient"
                          value={formData.max_patient}
                          onChange={handleInputChange}
                          className="form-control bg-light text-dark mt-3"
                          required
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="py-2">Avarage Consult Time</label>
                        <input
                          type="number"
                          name="avg_consult_time"
                          value={formData.avg_consult_time}
                          onChange={handleInputChange}
                          className="form-control bg-light text-dark mt-3"
                          required
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="py-2">Week Off</label>
                        <div>
                          <input
                            type="checkbox"
                            name="weekoffMonday"
                            checked={formData.weekoffSaturday}
                            onChange={handleCheckboxChange}
                          />
                          <label>Monday</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name="weekoffTuesday"
                            checked={formData.weekoffTuesday}
                            onChange={handleCheckboxChange}
                          />
                          <label>Tuesday</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name="weekoffWednesday"
                            checked={formData.weekoffWednesday}
                            onChange={handleCheckboxChange}
                          />
                          <label>Wednesday</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name="weekoffThursday"
                            checked={formData.weekoffThursday}
                            onChange={handleCheckboxChange}
                          />
                          <label>Thursday</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name="weekoffFriday"
                            checked={formData.weekoffFriday}
                            onChange={handleCheckboxChange}
                          />
                          <label>Friday</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name="weekoffSaturday"
                            checked={formData.weekoffSaturday}
                            onChange={handleCheckboxChange}
                          />
                          <label>Saturday</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name="weekoffSunday"
                            checked={formData.weekoffSunday}
                            onChange={handleCheckboxChange}
                          />
                          <label>Sunday</label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="py-2">Break Duration</label>
                        <input
                          type="number"
                          name="break_duration"
                          value={formData.break_duration}
                          onChange={handleInputChange}
                          className="form-control bg-light text-dark mt-3"
                          required
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="py-2">Break From</label>
                        <input
                          type="time"
                          name="break_from"
                          value={formData.breakStartTime}
                          onChange={handleInputChange}
                          className="form-control bg-light text-dark mt-3"
                          required
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="py-2">Break To</label>
                        <input
                          type="time"
                          name="break_to"
                          value={formData.breakEndTime}
                          onChange={handleInputChange}
                          className="form-control bg-light text-dark mt-3"
                          required
                        />
                      </div>
                    </div>

                    <button type="submit" className="btn btn-success mt-3">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
