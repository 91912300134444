import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const EmergencyModel = ({
  show,
  handleSubmit,
  handleInputChange,
  formData,
  modelToggle,
  isLoading,
}) => {
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title className="h6">Add New Emergency Token</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card card-body">
          <form onSubmit={() => handleSubmit()}>
            <div className="my-4">
              <label className="mb-2">Name</label>
              <input
                className="form-control"
                placeholder="Enter Name"
                onChange={(e) => handleInputChange(e)}
                name="name"
              />
            </div>
            <div className="mb-4">
              <label className="mb-2">Mobile</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Mobile"
                onChange={(e) => handleInputChange(e)}
                name="mobile"
                maxLength={10}
                minLength={10}
                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              />
            </div>
            <div className="my-4">
              <label className="mb-2">Age</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Age"
                onChange={(e) => handleInputChange(e)}
                name="dob"
              />
            </div>
            <div className="my-4">
              <label className="mb-2">Gender</label>
              <select
                className="form-control"
                onChange={(e) => handleInputChange(e)}
                name="gender"
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="mb-2">City</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter City"
                onChange={(e) => handleInputChange(e)}
                name="email"
              />
            </div>
            <div className="mb-3 text-end">
              <Button variant="secondary" onClick={modelToggle}>
                Close
              </Button>
              <button
                disabled={isLoading}
                className="btn btn-primary ms-2"
                type="submit"
              >
                {isLoading && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}{" "}
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};
