import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const RecallModel = ({
  show,
  handleSubmit,
  inputValue,
  setInputValue,
  modelToggle,
  isLoading,
}) => {
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title className="h6">
          Recall Token
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="">
            <input
              type="number"
              className="form-control"
              placeholder="Enter Minuts"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
          <div className="mt-3 text-end">
            <Button variant="secondary" onClick={modelToggle}>
              Close
            </Button>
            <button
              disabled={isLoading}
              className="btn btn-primary ms-2"
              type="submit"
            >
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}{" "}
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
