import React from "react";
import missionImg from "../image/slider1.jpg";
import vissionImg from "../image/slider2.jpg";
import Feedback from "./Feedback";
import Review from "./Review";

export const ReviewRating = () => {
  return (
    <div style={{ background: "#d0f3fac2" }}>
      <div className="container-fluid py-5">
        <div className="">
          <h2 className="text-center mb-4 pb-3">
            Share your review with MKITE
          </h2>
          <div className="row">
            <div className="col-md-6 mb-4">
              <Feedback />
            </div>
            <div className="col-md-6 text-center">
              <Review doctor_id={0} is_type="mkite" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
