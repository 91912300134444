import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { IoMdRefresh } from "react-icons/io";

export const SkipRecallModel = ({
  show,
  handleSubmit,
  handleSkipSubmit,
  inputValue,
  setInputValue,
  modelToggle,
  isLoading,
  tokenType,
  tokenInModelToggle,
  patient
}) => {
  return (
    <Modal show={show}>
      <Modal.Body>
      {tokenType == "pending" ? (
          <div className="d-flex justify-content-between">
            <h6 className="h6">Recall Token</h6>
            <button
              className="btn btn-info btn-sm"
              type="button"
              onClick={() => tokenInModelToggle(patient)}
              disabled={isLoading}
            >
              <span className="">
                {isLoading && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                <IoMdRefresh />
                Patient In
              </span>
            </button>
          </div>
        ) : (
          <h6 className="h6">Skip Recall Number</h6>
        )}
        <hr/>
        <form onSubmit={handleSubmit}>
          <div className="">
            <input
              type="number"
              className="form-control"
              placeholder="Enter Count Number"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
          <div className="mt-3 text-end">
            <Button variant="secondary" onClick={modelToggle}>
              Close
            </Button>
            <button
              disabled={isLoading}
              className="btn btn-primary ms-2"
              type="submit"
            >
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}{" "}
              Submit
            </button>
          </div>
        </form>

        {tokenType == "pending" && (
          <>
            <hr />
            <div
              className="d-flex justify-content-around"
              onClick={modelToggle}
            >
              <h5 className="mt-1">You want to skip the token directly.</h5>
              <button
                disabled={isLoading}
                className="btn btn-warning ms-2"
                type="button"
                onClick={handleSkipSubmit}
              >
                {isLoading && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Skip Token
              </button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
