import React from "react";
import missionImg from "../image/slider1.jpg";
import vissionImg from "../image/slider2.jpg";

export const Abouts = () => {
  return (
    <div className="py-5">
      <div className="container">
        <h2 className="text-website text-center pb-3">About Us</h2>
        <p className="text-center">
          MKite.in is a revolutionary healthcare application designed to
          transform the way doctors manage patient appointments. We understand
          the challenges doctors face in maintaining a smooth flow of patients,
          especially during peak hours. Long wait times can lead to patient
          frustration and disrupt clinic schedules. That's where MKite.in steps
          in.
        </p>
      </div>

      <div className="container">
        <div className="row my-4 pt-4">
          <div className="col-lg-6 col-md-12 mt-3 mt-lg-4">
            <h3 className="fs-3 text-website">Mission</h3>
            <p>
              To simplify and streamline appointment scheduling and check-in
              processes, ensuring a seamless experience for all.
            </p>
          </div>
          <div className="col-lg-6 col-md-12">
            <img src={missionImg} alt="Mission" className="img-fluid" />
          </div>
        </div>

        <div className="row my-4">
          <div className="col-lg-6 col-md-12">
            <img src={vissionImg} alt="Vision" className="img-fluid" />
          </div>
          <div className="col-lg-6 col-md-12 mt-3 mt-lg-4">
            <h3 className="fs-3 text-website">Vision</h3>
            <p className="">
              To become the leading platform for appointment flow management,
              transforming the way healthcare appointments are managed.
            </p>
          </div>
        </div>
      </div>

      {/* <div className="bg-info">
  <div className="container py-4">
    <div className="row">
      <div className="col-lg-6">
        <h2 className="text-light fs-4">Subscribe to Our Newsletter</h2>
        <p className="text-light fs-6 mb-3">Stay updated with our latest news and features.</p>
        <form>
          <div className="form-group">
            <input
              className="form-control fs-6"
              type="email"
              placeholder="Enter your email"
              aria-label="Enter your email for newsletter subscription"
            />
          </div>
          <button type="submit" className="btn btn-dark fs-6">Subscribe</button>
        </form>
      </div>
      <div className="col-lg-6">
        <h2 className="text-light fs-4">Submit Feedback</h2>
        <p className="text-light fs-6 mb-3">Help us improve our services by sharing your feedback.</p>
        <form>
          <div className="form-group">
            <input
              className="form-control fs-6"
              type="email"
              placeholder="Enter your email"
              aria-label="Enter your email for feedback submission"
            />
          </div>
          <button type="submit" className="btn btn-dark fs-6">Submit Feedback</button>
        </form>
      </div>
    </div>
  </div>
</div> */}
    </div>
  );
};
