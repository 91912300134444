import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { baseURl } from "../../utils";
import image from "../../image/slider2.jpg";
import authService from "../../services/authService";
import { useNavigate } from "react-router-dom";
export const DoctorRegister = () => {
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    confirm_password: "",
    category_id: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getCategory = async () => {
    const reqeustParam = { is_active: 1 };
    const resp = authService.categories(reqeustParam);
    resp
      .then(async (res) => {
        const resData = await res.json();
        if (resData.success === true) {
          const data = resData.data;
          setCategories(data);
        } else {
          Swal.fire({
            icon: "error",
            title: `${resData.message}`,
            text: `Time - `,
          });
        }
      })
      .catch((err) => {});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate form field
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setLoading(!loading);
    try {
      const endpoint = `${baseURl}/auth/register`;
      const data = {
        name: formData.name,
        mobile: formData.mobile,
        email: formData.email,
        password: formData.password,
        category_id: formData.category_id,
      };

      await axios
        .post(endpoint, data)
        .then((response) => {
          if (response.data.success) {
            navigate(`./verification/${formData.email}`);
          } else {
            setLoading(!loading);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response?.data?.message,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(
            "Error registering doctor: ",
            error.response.data.message
          );
          // Use SweetAlert to display an error message
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error?.response?.data?.message,
          });
        });
    } catch (error) {
      setLoading(!loading);
      console.error("Error fetching the API:", error);
    }
  };

  const validateForm = (formData) => {
    let errors = {};

    if (!formData.name.trim()) {
      errors.name = "Doctor name is required";
    }

    if (!formData.mobile.trim()) {
      errors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      errors.mobile = "Mobile number should be 10 digits";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }

    if (!formData.password.trim()) {
      errors.password = "Password is required";
    } else if (formData.password.trim().length < 8) {
      errors.password = "Password should be at least 8 characters";
    }

    if (formData.password !== formData.confirm_password) {
      errors.confirm_password = "Passwords do not match";
    }

    return errors;
  };
  useEffect(() => {
    getCategory();
  }, []);

  return (
    <div
      className="row justify-content-center py-5 mx-0"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="col-xl-4 col-lg-5 col-md-7 bg-light">
        <h1 className="mt-2 fs-2 text-center text-website">
          Doctor Registration
        </h1>
        <hr />
        <form
          onSubmit={handleSubmit}
          className="justify-content-center pb-lg-4 w-100"
        >
          <div className="d-flex flex-column px-2">
            <div className="mb-3">
              <label className="pb-1">Name</label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Doctor Name"
                required
              />
              {errors.name && <p className="error">{errors.name}</p>}
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="pb-1">Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  placeholder="7078049692"
                  maxLength={10}
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  required
                />
                {errors.mobile && <p className="error">{errors.mobile}</p>}
              </div>
              <div className="col-md-6 mb-3">
                <label className="pb-1">Category</label>
                <select
                  name="category_id"
                  className="form-control"
                  value={formData.category_id}
                  onChange={handleInputChange}
                >
                  <option value="">Select Category</option>
                  {categories.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label className="pb-1">Email</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="example@gmail.com"
                required
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="pb-1">Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder="*********"
                  required
                />
                {errors.password && <p className="error">{errors.password}</p>}
              </div>
              <div className="col-md-6 mb-3">
                <label className="pb-1">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="confirm_password"
                  value={formData.confirm_password}
                  onChange={handleInputChange}
                  placeholder="*********"
                  required
                />
                {errors.confirm_password && (
                  <p className="error">{errors.confirm_password}</p>
                )}
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-success mt-3"
              disabled={loading}
            >
              {loading ? "Registering..." : "Register"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
