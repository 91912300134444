import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const DeleteModel = ({showModal, setIsDeleteModel, deleteData}) => {
  return (
    <Modal show={showModal} onHide={() => setIsDeleteModel(false)}>
        <Modal.Body>
            <p>Are you sure you want to delete this?</p>
            <div className="col-md-12 mb-3 text-end">
              <Button className="text-end" variant="primary" type="button" onClick={deleteData} danger>
                Save
              </Button>
              <Button className="text-end" variant="primary" type="button"  success>
                No
              </Button>
            </div>
        </Modal.Body>
      </Modal>
  )
}

export default DeleteModel