import React from "react";
import { Route, Routes, Navigate, Redirect } from "react-router-dom";
import { DashboardData } from "../views/admin/DashboardData";
// import { ReportData } from "../views/admin/ReportData";
import { AddToken } from "../views/admin/AddToken";
import { DoctorStep3 } from "../auth/ragister/DoctorStep3";
import { EditToken } from "../views/admin/EditToken";
import { Notice } from "../views/admin/Notice";
import { Shift } from "../views/admin/Shift";
import { Staff } from "../views/admin/Staff";
import { FeeType } from "../views/admin/FeeType";
import {ReportData}  from "../views/admin/ReportData";
import Invoice from "../views/admin/Invoice";
import Role from "../views/admin/Role";
import Promissies from "../views/admin/Promissies";
import { Wallet } from "../views/admin/Wallet";
import { ChangePassword } from "../views/admin/ChangePassword";
import PatientList from "../views/admin/PatientList";
import Message from "../views/admin/Message";
import MessageSend from "../views/admin/MessageSend";
import SubPlan from "../views/admin/SubPlan";
import { EditProfile } from "../views/admin/EditProfile";

// import { Invoice } from "../views/admin/Invoice";

export const AdminRoute = () => {
  return (
    <Routes>
      <Route path="admin/dashboard" element={<DashboardData />} />
      <Route path="admin/change-password" element={<ChangePassword />} />
      <Route path="admin/report" element={<ReportData />} />
      <Route path="admin/patient/list" element={<PatientList />} />
      <Route path="admin/message/list" element={<Message />} />
      <Route path="admin/message/send" element={<MessageSend />} />
      <Route path="admin/token" element={<AddToken />} />
      <Route path="admin/notice" element={<Notice />} />
      <Route path="admin/shifts" element={<Shift />} />
      <Route path="admin/staff" element={<Staff />} />
      <Route path="admin/fee-type" element={<FeeType />} />
      <Route path="admin/invoice" element={<Invoice />} />
      <Route path="admin/role" element={<Role />} />
      <Route path="admin/wallet/:subscribe" element={<Wallet />} />
      <Route path="admin/wallet" element={<Wallet />} />
      <Route path="admin/promisses/:role_id" element={<Promissies />} />
      <Route path="admin/token/edit/:token_id" element={<EditToken />} />
      <Route path="admin/plans/list" element={<SubPlan />} /> 
      <Route path="admin/register" element={<DoctorStep3 />} />
      <Route path="admin/profile" element={<EditProfile />} />
    </Routes>
  );
};
