import React, { useState } from "react";
import contact from "../image/contact.png";
import { AiOutlineWhatsApp, AiFillMail, AiFillMobile } from "react-icons/ai";
export const Contact = () => {
  // Initialize form fields and submit state
  const [formData, setFormData] = useState({
    userType: "",
    name: "",
    email: "",
    number: "",
    message: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitted(true);
  };
  return (
    <div>
     <div className="container">
  <h2 className="text-website text-center pt-4">Contact Us</h2>
</div>

<section className="section_padding partnerSec1 mt-4">
  <div className="container">
    <div className="row justify-content-center align-items-center">
      <div className="col-md-4 col-lg-6">
        <div className="about_us_img">
          <img src={contact} width="100%" height="auto" className="rounded-circle img-hover-effect" alt="Contact Person" />
        </div>
      </div>
      <div className="col-md-8 col-lg-6">
        <div className="">
          <h4 className="text-start text-website">Contact Us Now</h4>
          <hr />
          <div className="contact-info">
            <div className="d-flex mb-3">
              <h4 className="w-25">Mobile No.</h4>
              <h4><a href="tel:6395819007" className="text-decoration-none"><AiFillMobile /> 6395819007</a></h4>
            </div>
            <div className="d-flex mb-3">
              <h4 className="w-25">Email Id</h4>
              <h4><a href="mailto:mkiteindia@gmail.com" className="text-decoration-none"><AiFillMail /> mkiteindia@gmail.com</a></h4>
            </div>
            <div className="d-flex mb-3">
              <h4 className="w-25">Connect to</h4>
              <h4><a href="https://wa.me/916395819007" className="text-decoration-none"><AiOutlineWhatsApp /> WhatsApp</a></h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  );
};
