import React from "react";
import { Dashboard } from "./Dashboard";
import "react-toastify/dist/ReactToastify.css";
import { DOCTOR_URLS } from "../../utils/constent";
import Header from "./Header";
import { TokenCreate } from "./components/TokenCreate";
import Head from "./components/Head";

export const AddToken = () => {
  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "80px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <Head />
          <div className="d-flex justify-content-center">
            <div className="col-md-9 col-lg-8 col-xl-6 col-sm-12">
              <TokenCreate urls={DOCTOR_URLS} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
