import React from "react";

const ShiftComponent = ({shiftChange, shifts, shift_id, status = false}) => {
  return (
    <div>
      <select
        style={{ borderColor: "#00a19a", border: "2px solid" }}
        className="form-control py-2"
        placeholder="Enter Name"
        onChange={(e) => shiftChange(e)}
        name="shift_id"
        value={shift_id}
        disabled={status}
      >
        <option value="">Select Shift</option>
        {shifts.map((item, index) => (
          <option value={item.id} key={index}>{item.name}</option>
        ))}
      </select>
    </div>
  );
};

export default ShiftComponent;
