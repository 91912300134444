import React, { useEffect, useState } from "react";
import authService from "../../services/authService";
import { AUTH_TOKEN, baseURl } from "../../utils";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { Dashboard } from "./Dashboard";
import Head from "./components/Head";

const SubPlan = () => {
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const getPlans = async () => {
    try {
      const reqeustParam = {};
      const resp = await authService.fatchPlan(reqeustParam);
      const resData = await resp.json();
      if (resData.success) {
        setPlans(resData.data);
      }
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  const handleSubmit = (plan_id) => {
    setIsLoading(true);
    axios
      .post(
        `${baseURl}/doctor/subscription`,
        { plan_id: plan_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        //console.log(response.data);
        if (response.data && response.data.data) {
          window.location.href = response.data.data;
        }
        return 0;
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
        if (error.response.data.message == "Unauthorized") {
          Swal.fire({
            title: "Unauthorized",
            text: "You are Unauthorized. Please login first.",
            type: "error",
            showCancelButton: true,
            confirmButtonColor: "#00a19aad",
            confirmButtonText: "Login",
            closeOnConfirm: false,
          }).then((res) => {
            if (res.isConfirmed) {
              navigate(`../login`);
            }
          });
        }
      });
  };

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "60px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <Head />
          <div className="main-container">
            <div style={{ backgroundColor: "#00ff1b2b" }}>
              <div className="container py-4">
                <div className="text-center pb-2">
                  <h4 className="h2">Plan and Pricing</h4>
                  <p>Choose you best plan and use mkite services</p>
                </div>
                <div className="row justify-content-between ">
                  {plans.map((item) => (
                    <div className="col-lg-3 col-md-6 text-center px-2">
                      <div className="card card-body p-0">
                        <p
                          className="card-header mt-0 h3"
                          style={{
                            backgroundColor: "#00a19aad",
                            color: "white",
                            fontWeight: "400",
                          }}
                        >
                          {item.title}
                        </p>
                        <div className="p-2">
                          <h4>{item.days} days</h4>
                          <p>{item.description}</p>
                          <p className="h4">₹{item.discount} discount</p>
                          <p className="h2" style={{ color: "#ca1f1f" }}>
                            ₹{item.price}-/
                          </p>
                          <button
                            disabled={isLoading}
                            onClick={() => handleSubmit(item.id)}
                            className="btn btn-info my-2 px-5"
                          >
                            {isLoading && (
                              <span className="spinner-border spinner-border-sm mr-1"></span>
                            )}{" "}
                            Pay Now
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubPlan;
